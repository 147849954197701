.filters {
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: space-between;

  background-color: var(--container-color);
  border-bottom: 1px solid var(--border-color);
}

@media (min-width: 768px) {
  .filters {
    margin-bottom: 1.5rem;
  }
}

.filters__subscribe svg {
  width: 1rem;
  height: 1rem;
  stroke-width: 1.75;
  margin-bottom: -2px;
  vertical-align: baseline;
}

.filters__projects {
  margin-left: auto;
  position: relative;
}

.filters__projects__trigger {
  z-index: 5;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  padding-left: 0.625rem;
  border: 1px solid transparent;
  border-bottom: 0;

  border-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filters__projects__trigger svg {
  width: 1rem;
  height: 1rem;
  stroke-width: 3;
  margin-left: 2px;
  margin-bottom: -2px;
}

.filters__projects:hover .filters__projects__nav {
  display: block;
}

.filters__projects:hover .filters__projects__trigger {
  border-color: var(--border-color);
  background-color: var(--container-color);
}

.filters__projects__nav {
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 2;
  display: none;
  border-top: 0;
  margin-top: 3px;
  min-width: 120%;
  list-style: none;
  overflow: hidden;
  position: absolute;
  font-size: 0.9375rem;
  padding-top: 0.3125rem;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);

  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  background-color: var(--container-color);
  border-top-right-radius: 0;
}

.filters__projects__nav li {
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  padding: 0.5rem 1rem;
}

.filters__projects__nav li:hover {
  color: var(--color-yiq);
  background-color: var(--color-primary);
}
