*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: var(--font-size);
}

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  overscroll-behavior-y: none;

  color: var(--text-color);
  font-family: var(--font-family);
  background-color: var(--bg-color);

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-weight: 600;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: auto;
}

button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.no-result {
  margin: 2rem 0;
  text-align: center;

  color: var(--muted-color);
}

#onset-root {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  flex-direction: column;
}

#onset-root > .page-container {
  flex: 1;
  overflow: auto;
  padding-top: 1rem;
}
