footer {
  bottom: 0;
  width: 100%;
  padding: 1rem;
  position: sticky;
  text-align: center;
  font-size: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.04) 0px -10px 10px 1px;

  background-color: var(--container-color);
  border-top: 1px solid var(--border-color);
}
