.header {
  top: 0;
  z-index: 6;
  padding: 1rem 0;
  position: sticky;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 10px -1px;

  background-color: var(--container-color);
  border-bottom: 1px solid var(--border-color);
}

.header > .page-container {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: space-between;
}

.header__toggle {
  margin: -0.25rem 0;
  z-index: 2;
  width: 200px;
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: 50px;
  font-size: 0.875rem;
  display: inline-block;

  border: 2px solid var(--bg-color);
  background-color: var(--bg-color);
}

.header__toggle input {
  position: absolute;
  left: -999px;
}

.header__toggle span {
  width: 50%;
  float: left;
  z-index: 2;
  display: block;
  padding: 4px 0;
  cursor: pointer;
  font-weight: 500;
}

.header__toggle input + span:before {
  top: 0px;
  left: 0px;
  width: 50%;
  z-index: -1;
  content: ' ';
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 50px;
  background-color: var(--color-primary);
  transition: left 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.header__toggle input:checked + span + span {
  color: var(--color-yiq);
}

.header__toggle input:not(:checked) + span {
  color: var(--color-yiq);
}

.header__toggle input:checked + span:before {
  left: 50%;
}

.header__close,
.header__link {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: var(--text-color);
}

.header__close {
  border: 0;
  padding: 0;
  cursor: pointer;
  font-weight: 600;
  margin-top: -2px;
  font-size: 1.5rem;
  background-color: transparent;
}

.header__link:hover,
.header__close:hover {
  color: var(--color-primary);
}
